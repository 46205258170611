import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { datadogRum } from '@datadog/browser-rum';
import "./index.css"

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://583c6c0571ac46c0a27846281663efc5@o558323.ingest.sentry.io/5691650",
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  
  datadogRum.init({
    applicationId: '6e1d6926-6e6b-441f-935e-d37f42e71ab4',
    clientToken: 'pub3ad8edc189c9fb1c674b536e483cfeee',
    site: 'datadoghq.com',
    service:'oconnor-frontend',
    env:'prod',
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true
  });
}


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
